import { Component, Input } from '@angular/core';

import { NavigationService } from '@app/shared/services/navigation';
import { NotificationService } from '@app/shared/components/notification';

@Component({
  selector: 'mcp-error-handling-overlay',
  templateUrl: './error-handling-overlay.component.html',
  styleUrls: ['./error-handling-overlay.component.scss'],
  providers: [NotificationService]
})
export class ErrorHandlingOverlayComponent {
  @Input() errorCode: number = 0;

  constructor(
    private readonly _notificationService: NotificationService,
    private readonly _navigationService: NavigationService
  ) {}

  show(): void {
    this._notificationService.show();
  }

  hide(): void {
    this._notificationService.hide();

    const returnScreen: string[] | null =
      this._navigationService.getReturnScreenFromErrorHandlingOverlay();

    if (Array.isArray(returnScreen)) {
      this._navigationService.navigate(returnScreen);
    }
  }
}
