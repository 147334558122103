import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable, tap } from 'rxjs';

import { AbstractMcpDeviceService } from '../services';
import { ROUTES } from '@app/app-routing.config';

@Injectable()
export class ConnectedGuard  {
  public constructor(
    private readonly router: Router,
    private readonly mcpDeviceService: AbstractMcpDeviceService
  ) {}

  canLoad(): Observable<boolean> {
    return this.mcpDeviceService.clbk_connected().pipe(
      tap((isConnected: boolean) => {
        if (isConnected) {
          return true;
        }
        this.router.navigate([ROUTES.LANDING_PAGE]);
        return false;
      })
    );
  }
}
