import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'mcp-leave-button',
  templateUrl: 'leave-button.component.html',
  styleUrls: ['leave-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LeaveButtonComponent {
  @Output() public buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  public onClick(): void {
    this.buttonClicked.emit();
  }
}
