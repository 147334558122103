import { Component, Input, OnInit } from '@angular/core';

export enum StatusSignalType {
  OK = 'ok',
  WARN = 'warn',
  ALARM = 'alarm',
  NEUTRAL = 'neutral'
}
@Component({
  selector: 'mcp-status-signal',
  templateUrl: 'status-signal.component.html',
  styleUrls: ['./status-signal.component.scss']
})
export class StatusSignalComponent implements OnInit {
  @Input() title: string;
  @Input() type: StatusSignalType = StatusSignalType.NEUTRAL;

  ngOnInit() {
    if (!this.title || typeof this.title !== 'string') {
      switch (this.type) {
        case StatusSignalType.OK:
          this.title = 'OK';
          break;
        case StatusSignalType.WARN:
          this.title = 'Warning';
          break;
        case StatusSignalType.ALARM:
          this.title = 'Serious Issue';
          break;
        default:
          this.title = 'None';
      }
    }
  }
}
