export enum Selector {
  NOT_AVAILABLE = 'notAvailable'
}

export enum SelectorSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  FULL = 'full'
}

export enum Storage {
  LANGUAGE = 'language'
}

export enum FlashEvent {
  READ_ERROR = 'readError',
  NO_FILE_SELECTED = 'noFileSelected',
  NO_BLE_CONNECTION = 'noBleConnection',
  FLASH_SUCCESS = 'flashSuccess',
  FLASH_FAILURE = 'flashFailure',
  FLASH_ERROR_FIRST_BYTES = 'flashErrorFirstBytes',
  FLASH_ERROR_LAST_BYTES = 'flashErrorLastBytes',
  FLASH_ENDED = 'flashEnded'
}

export enum DoorMovement {
  END_POSITION_OPEN_INSIDE,
  END_POSITION_CLOSE,
  IME_POSITION_OPEN,
  IME_POSITION_CLOSE,
  POSITION_STOP,
  IME_SP_LS_POINT,
  IME_SP_DEACTIVATION_POINT_CLOSE,
  IME_SP_DEACTIVATION_POINT_OPEN,
  IME_SP_SAFETY_EDGE_TEST_POINT,
  END_POSITION_OPEN_OUTSIDE
}

export enum DoorState {
  OPEN = 'door-open',
  CLOSED = 'door-closed',
  INTERMEDIATE_OPEN = 'door-imo',
  INTERMEDIATE_CLOSED = 'door-imc'
}

export enum Action {
  EXECUTE = 'execute',
  ADD = 'add',
  DELETE = 'delete'
}