import { Injectable, Input } from '@angular/core';
import { AbstractMcpDeviceService, McpDeviceService } from '@app/core/services';
import { DoorState } from '@app/shared/enums/enums';
import { DeviceResponse } from '@app/shared/interfaces/interfaces';
import { RegisterService } from '@app/shared/services/register.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemoteControlService extends RegisterService {
  updateOnId: BehaviorSubject<number[] | undefined> = new BehaviorSubject<
    number[] | undefined
  >(undefined);
  updateOnDoorPosition: BehaviorSubject<DoorState> = new BehaviorSubject<
    DoorState
  >(DoorState.INTERMEDIATE_CLOSED);
  ids = [McpDeviceService.ID_CURRENT_DOOR_POSITION_IN_PERCENT];

  constructor(private readonly _mcpDeviceService: AbstractMcpDeviceService) {
    super(_mcpDeviceService);
    this.init();
  }

  override async init() {
    super.init();

    const updateSub = this._mcpDeviceService.updateOnId.subscribe(
      (json: DeviceResponse | null) => {
        if (json && this.ids.includes(Number(json.id))) {
          this.updateOnId.next(json.d);
        }
      }
    );
    this.subscriptions.push(updateSub);
    const doorPositionSub = this._mcpDeviceService.currentDoorPosition$.subscribe(value => {
      let doorPosition = DoorState.OPEN;
      if (Number(value) < 2) {
        doorPosition = DoorState.CLOSED;
      } else if (Number(value) < 26) {
        doorPosition = DoorState.INTERMEDIATE_CLOSED;
      } else if (Number(value) < 75) {
        doorPosition = DoorState.INTERMEDIATE_OPEN;
      }
      this.updateOnDoorPosition.next(doorPosition);
    });
    this.subscriptions.push(doorPositionSub);
    await this._mcpDeviceService.registerIds(this.ids);
  }

  async unregisterIds() {
    try {
      await this._mcpDeviceService.unregisterIds(this.ids);
      console.log('[RemoteControlService] - unregisterIds - success');
    } catch (error) {
      console.log('[RemoteControlService] - unregisterIds - error:', error);
    }
  }
}
