export enum PositionTypeEnum {
  epo = 'epo',
  epc = 'epc',
  ipo = 'ipo',
  ipc = 'ipc',
  deactivationPointOpen = 'dpo',
  deactivationPointClose = 'dpc',
  lsPoint = 'ls',
  safetyEdgeTestPoint = 'setp'
}
