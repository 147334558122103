import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'mcp-info-button',
  templateUrl: 'info-button.component.html',
  styleUrls: ['./info-button.component.scss']
})
export class InfoButtonComponent {
  @Output() buttonClicked = new EventEmitter<void>();

  infoButtonClickHandler(): void {
    this.buttonClicked.next();
  }
}
