export function getDeepCopy(object: any) {
  return JSON.parse(JSON.stringify(object));
}

export function createArrayNumbersFromTo(from: number, to: number, step: number = 1) {
  const returnValue: number[] = [];
  if (from < to) {
    for (let i = from; i < to; i+=step) {
      returnValue.push(i);
    }
  } else {
    for (let i = from; i > to; i-=step) {
      returnValue.push(i);
    }
  }

  return returnValue;
}

export function createRandomUUID() {
  let dt = new Date().getTime();
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
}

export function createIdFromNumbersWithLength(length: number) {
  let result = '';
  const characters = '0123456789';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}

export function createIdFromCharsWithLength(length: number) {
  let result = '';
  const characters = 'abcdefghijklmnopqerstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
