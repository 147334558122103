import {
  AbstractMcpDeviceService,
  McpActiveProcessService,
  // McpDeviceMockService,
  McpDeviceService
} from './services';
import { ErrorHandlingOverlayComponent, HeaderComponent } from './components';

import { ConnectedGuard } from '@core/guards';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NotificationModule } from '@shared/components/notification/notification.module';
import { Router } from '@angular/router';
import { TranslocoRootModule } from '@core/transloco-root.module';
import { McpDeviceCommService } from './services/mcp-device-comm.service';

const declarations = [HeaderComponent, ErrorHandlingOverlayComponent];
const providers = [
  ConnectedGuard,
  Router,
  // -- DEVELOPER MOCKED SERVICE --
  /*
  {
    provide: AbstractMcpDeviceService,
    useFactory: (router: Router) => new McpDeviceMockService(router),
    deps: [Router]
  },
  */
  // -- REAL DATA SERVICE --
  HttpClient,
  McpDeviceCommService,
  {
    provide: AbstractMcpDeviceService,
    useFactory: (
      router: Router,
      http: HttpClient,
      comm: McpDeviceCommService
    ) => new McpDeviceService(router, http, comm),
    deps: [Router, HttpClient, McpDeviceCommService]
  },
  McpActiveProcessService
];
const exports = [...declarations, HttpClientModule, TranslocoRootModule];
const imports = [NotificationModule];

@NgModule({
  declarations,
  providers,
  exports,
  imports
})
export class CoreModule {}
