<h2 mat-dialog-title>{{ 'common.areYouSure' | transloco }}</h2>
<mat-dialog-content>
  <h2 *ngIf="data?.title">{{ data.title | transloco }}</h2>
  <p *ngIf="data?.text">{{ data.text | transloco }}</p>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close class="negative mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base">{{ 'button.cancel' | transloco }}</button>
  <button mat-button [mat-dialog-close]="true" class="positive mdc-button mat-mdc-button mat-unthemed mat-mdc-button-base">
    {{ 'button.ok' | transloco }}
  </button>
</mat-dialog-actions>