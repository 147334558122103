<mcp-notification-dialog
  [title]="'errorHandlingOverlay.title' | transloco"
  [hasCloseButton]="false"
>
  <div
    class="error-handling-overlay-container h-100 pb-2 d-flex flex-column justify-content-between"
  >
    <h1 class="error-handling-overlay-container__message">
      {{ 'errorCodeText.ect_' + errorCode | transloco }}
    </h1>
    <div class="error-handling-overlay-container__box">
      <h2 class="error-handling-overlay-container__box-title">
        {{ 'errorHandlingOverlay.messageCode' | transloco }}
      </h2>
      <span class="error-handling-overlay-container__box-code">
        {{ errorCode }}
      </span>
      <img
        class="error-handling-overlay-container__box-warning-icon"
        src="assets/icons/warning-icon-3.svg"
        alt=""
      />
    </div>
    <div class="error-handling-overlay-container__separator"></div>
    <mcp-button
      class="error-handling-overlay-container__action-button"
      (buttonClicked)="hide()"
      [buttonText]="'errorHandlingOverlay.actionButton' | transloco"
    ></mcp-button>
  </div>
</mcp-notification-dialog>
