import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'mcp-back-button',
  templateUrl: 'back-button.component.html',
  styleUrls: ['back-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BackButtonComponent {
  @Input() buttonText = '';
  @Input() disabledButton: boolean | null = false;

  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  onClick(): void {
    this.buttonClicked.emit();
  }
}
