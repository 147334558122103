import { AbstractMcpDeviceService } from '@app/core/services';
import { Subscription } from 'rxjs';

export abstract class RegisterService {
  subscriptions: Subscription[] = [];
  isInitialized = false;
  constructor(public readonly mcpDeviceService: AbstractMcpDeviceService) {
    this.mcpDeviceService.updateOnDisconnect.subscribe((item) => {
      if (typeof item === 'boolean' && item) {
        this.reset();
      }
    });
  }

  init() {
    this.isInitialized = true;
  }

  reset() {
    console.log('RegisterService - reset +++++++');
    this.isInitialized = false;
    this.subscriptions.forEach((item) => item.unsubscribe());
  }
}
