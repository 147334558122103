<button
  class="mcp-press-release-button"
  [ngClass]="{ 'mcp-press-release-button--disabled': disabled }"
  [disabled]="disabled"
  (contextmenu)="contextMenuHandler($event)"
  (mousedown)="buttonPressedHandler($event)"
  (mouseup)="buttonReleasedHandler($event)"
  (mouseleave)="buttonLeftHandler($event)"
  (touchstart)="buttonPressedHandler($event)"
  (touchend)="buttonReleasedHandler($event)"
  (touchcancel)="buttonReleasedHandler($event)"
>
  <ng-content></ng-content>
</button>
