import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'mcp-searchfield',
  templateUrl: 'searchfield.component.html',
  styleUrls: ['searchfield.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class SearchFieldComponent {
  @Input() description = '';
  @Output() changeEvent: EventEmitter<string> = new EventEmitter<string>();

  isDisabled = false;
  searchTerm = '';

  search() {
    this.changeEvent.emit(this.searchTerm);
  }
}
