import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Language } from '../interfaces/interfaces';
import { Storage } from '../enums/enums';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private readonly _translocoService: TranslocoService) {}

  public setLanguage(language: Language) {
    sessionStorage.setItem(Storage.LANGUAGE, JSON.stringify(language));
  }

  public getLanguage() {
    const languageString = sessionStorage.getItem(Storage.LANGUAGE);
    let parsed: Language | null = null;
    if (languageString) {
      parsed = JSON.parse(languageString) as Language;
    }
    return parsed;
  }
}
