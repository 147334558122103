import { Injectable, OnDestroy } from '@angular/core';
import {
  BehaviorSubject,
  mapTo,
  Subject,
  switchMap,
  takeUntil,
  timer
} from 'rxjs';
import { DEFAULT_AUTO_CLOSE_DELAY } from '@shared/components/notification/auto-close-delay.const';

export interface NotificationActionOptions {
  autoClose?: boolean;
  autoCloseDelay?: number;
}

@Injectable()
export class NotificationService implements OnDestroy {
  private readonly _triggerAutoClose = new Subject<number>();
  private readonly _destroy$ = new Subject<void>();

  visible$ = new BehaviorSubject<boolean>(false);

  autoCloseEvent = this._triggerAutoClose.pipe(
    switchMap((delay) => timer(delay)),
    mapTo(true),
    takeUntil(this._destroy$)
  );

  constructor() {
    this.autoCloseEvent.subscribe(() => this.hide());
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  show(options?: NotificationActionOptions): void {
    this.visible$.next(true);

    if (options?.autoClose) {
      const delay = options?.autoCloseDelay ?? DEFAULT_AUTO_CLOSE_DELAY;

      this._triggerAutoClose.next(delay);
    }
  }

  hide(): void {
    this.visible$.next(false);
  }
}
