<div *ngIf="isMultiple" class="container" mcpAppDragnDrop (fileDropped)="onFileDropped($event)">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    multiple
    (change)="fileBrowseHandler($event)"
  />
  <div class="upload-pic">
    {{ title | transloco }}
    <label for="fileDropRef"><img src="./assets/icons/cloud-upload.svg" /></label>
  </div>
</div>
<div *ngIf="!isMultiple" class="container" mcpAppDragnDrop (fileDropped)="onFileDropped($event)">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    (change)="fileBrowseHandler($event)"
  />
  <div class="upload-pic">
    {{ title | transloco }}
    <label for="fileDropRef"><img src="./assets/icons/cloud-upload.svg" /></label>
  </div>
</div>
