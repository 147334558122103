<ng-container>
  <!-- <mcp-header></mcp-header> -->
  <mat-toolbar class="mcp-toolbar" *ngIf="headerIsVisible">
    <mat-toolbar-row>
      <button type="button" mat-button class="menu-button" *ngIf="sidebarIsVisible" (click)="drawer.toggle()">
        <img src="./assets/icons/menu.svg"/>
      </button>
      <div class="logo-container" (click)="logoClicked()">
        <img src="./assets/images/app-logo.png" class="img-fluid mx-auto" title="mcp-logo" alt="mcp-logo" />
      </div>
      <button type="button" mat-button class="menu-button" *ngIf="sidebarRightIsVisible" (click)="drawerRight.toggle()">
        <img src="./assets/icons/garage_door.svg"/> 
      </button>
      <div class="version">{{ appVersion }}</div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-drawer-container>
    <mat-drawer #drawer mode="over">
      <mat-nav-list>
          <mat-list-item *ngFor="let item of menuItems" (click)="!item.disabled && navItemClicked(item); drawer.toggle()">{{ item.label | transloco }}</mat-list-item>
      </mat-nav-list>
      <mcp-language-selector></mcp-language-selector>
      <mcp-leave-button (buttonClicked)="onClickLeave()"></mcp-leave-button>
    </mat-drawer>
    <mat-drawer-content>
      <div [ngClass]="{ 'main-container': headerIsVisible }">
        <router-outlet></router-outlet>
        <mcp-error-handling-overlay
          [errorCode]="(errorOccured$ | async)!"
        ></mcp-error-handling-overlay>
      </div>
    </mat-drawer-content>
    <mat-drawer #drawerRight mode="over" position="end">
      <mcp-remote-control *ngIf="sidebarRightIsVisible"
        [description]="'remoteControl.description'"
        [hasOpenButton]="hasButtons"
        [hasCloseButton]="hasButtons"
        [hasStopButton]="hasButtons"
        [hasIntermediateOpenButton]="hasButtons"
        [hasIntermediateCloseButton]="hasButtons"
        [hasDeactivationPointOpenButton]="hasButtons"
        [hasDeactivationPointCloseButton]="hasButtons"
        [hasLSPointButton]="hasButtons"
        [hasSafetyEdgeTestPointButton]="hasButtons"
        >
      </mcp-remote-control>
    </mat-drawer>
    <mat-drawer-content>
      <div [ngClass]="{ 'main-container': headerIsVisible }">
        <router-outlet></router-outlet>
        <mcp-error-handling-overlay
          [errorCode]="(errorOccured$ | async)!"
        ></mcp-error-handling-overlay>
      </div>
    </mat-drawer-content>
  </mat-drawer-container>
  
</ng-container>


