import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AbstractMcpDeviceService } from '@app/core/services';

@Injectable({
  providedIn: 'root'
})
export class RoutingHistoryService {
  private _previousUrl: string;
  private _currentUrl: string;

  constructor(
    private readonly _router: Router,
    private readonly _mcpDeviceService: AbstractMcpDeviceService
  ) {
    
  }

  public init() {
    this._currentUrl = this._router.url;
    this._router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._previousUrl = this._currentUrl;
        this._currentUrl = event.url;
        this.handleRouteChange();
      };
    });
  }

  private handleRouteChange() {
    console.log('[RoutingHistoryService] handleRouteChange - previousUrl::currentUrl ' + this._previousUrl + ' :: ' + this._currentUrl);
  }
}
