/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable object-shorthand */

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';

import {
  BehaviorSubject,
  catchError,
  firstValueFrom,
  from,
  Observable,
  throwError
} from 'rxjs';

import {
  AbstractMcpDeviceService,
  AbstractMcpDeviceCommService,
  McpDeviceError
} from '@core/services';

import { ButtonEventEnum, ButtonTypeEnum, PositionTypeEnum } from '@core/enums';
import { DeviceResponse } from '@app/shared/interfaces/interfaces';

const zeroPad = (num: number, places: number) =>
  String(num).padStart(places, '0');

// MCP enums
export enum DoorLearnStateEnum {
  _unknown = -1,
  learned,
  learning,
  unlearned,
  imeLearning,
  runInNeeded
}

export enum EndPositionTypeEnum {
  _unknown = -1,
  awg,
  mechanicalEndSwitches,
  autolearnEndPositionType
}

export enum RotatingFieldEnum {
  _unknown = -1,
  default,
  reversedRotating
}

export enum LearningCommandEnum {
  _unknown = -1,
  requestLearning,
  saveOpen,
  saveClose,
  skipOpen,
  skipClose,
  abortLearning,
  driveOpen,
  driveClose,
  driveStop
}

export enum LearningImePositionEnum {
  _unknown = -1,
  requestLearning,
  abortLearning,
  saveImeOpen,
  saveImeClose,
  spDeactivationOpen,
  spDeactivationClose,
  spLsPoint,
  spSafetyEdgeTestPoint,
  stopDriving,
  openDriving,
  closeDriving
}

export enum DeleteImePositionEnum {
  _unknown = -1,
  allImePositions,
  allSpPositions,
  allPositions,
  imePositionOpen,
  imePositionClose,
  spDeactivationOpen,
  spDeactivationClose,
  spLsPoint,
  spSafetyEdgeTestPoint
}

export enum ImpulsTargetEnum {
  _unknown = -1,
  endPositionOpen,
  endPositionClose,
  imePositionOpen,
  imePositionClose,
  positionStop,
  posSpLsPoint,
  posSpDeactivationClose,
  posSpDeactivationOpen,
  posSpSafetyEdgeTestPoint
}

export enum MotorStateEnum {
  _unknown = -1,
  notInstalled,
  stop,
  close,
  open
}

export enum CurrentDoorPositionEnum {
  _unknown = -1,
  unknownInvalid,
  untrainedEndPosition,
  overEndPosition,
  endPositionOpen,
  intermediateOpen,
  betweenEndPositions,
  betweenEpCloseAndImeOpen,
  betweenEpOpenAndImeClose,
  betweenEpCloseAndImeClose,
  betweenEpOpenAndImeOpen,
  betweenImeOpenAndImeClose,
  intermediateClose,
  endPositionClose,
  overEndPositionClose
}

// internal
enum MoveTypeEnum {
  endpos = 'endpos',
  intermediatepos = 'intermediatepos'
}

enum MoveStateEnum {
  stopped = 'stopped',
  running = 'running',
  stopping = 'stopping'
}

@Injectable()
export class McpDeviceService extends AbstractMcpDeviceService {
  // Informations readout
  static readonly ID_HARDWARE_VERSION = 31;
  static readonly ID_VERSION_SAFETY_SW = 65528;
  static readonly ID_VERSION_COMFORT_SW = 48;
  static readonly ID_SERIAL_NUMBER = 20;
  static readonly ID_DEVICE_ID = 141;
  static readonly ID_DEVICE_NAME = 145;
  static readonly ID_DOOR_CYCLE_CTR = 40;
  static readonly ID_POWER_ON_HOURS = 32;
  static readonly ID_DOOR_RUN_TIMER_TOTAL = 38;
  static readonly ID_OPEN_TIME_CTR = 71;
  static readonly ID_CURRENT_DOOR_POSITION_IN_PERCENT = 36;
  static readonly ID_CURRENT_VALUE_AWG = 37;
  static readonly ID_DOOR_LEARN_STATE = 27;

  // static readonly INFO_READOUT_POST_URL = 'https://api.test.yourgateway.io/api/box-reports/add';
  static readonly INFO_READOUT_POST_URL = 'https://pullreportcustomertest.azurewebsites.net/api/box-reports/add';

  static readonly INFO_READOUT_POST_API_KEY = 'Xnd0JiFcbURjdHhTIDQe3MgI';
  static readonly INFO_READOUT_POST_CLIENT_ID = 'maveoserviceapp';

  // Setup/Test positions
  static readonly ID_END_POSITION_TYPE = 2;
  static readonly ID_ROTATING_FIELD = 1;
  static readonly ID_LEARNING_COMMAND = 5;
  static readonly ID_LEARNING_IME_POSITION = 56;
  static readonly ID_DELETE_IME_POSITION = 65;
  static readonly ID_INTERMEDIATE_POSITIONS = 4;
  static readonly ID_IMPULS_TARGET = 44;
  static readonly ID_MOTOR_STATE = 25;
  static readonly ID_CURRENT_DOOR_POSITION = 47;
  static readonly ID_DIAGNOSIS_END_POSITION_FINE_SETTINGS = 102;

  // General Settings
  static readonly ID_FACTORY_RESET = 7;
  static readonly ID_RESTART = 29;
  static readonly  ID_BEHAVIOUR_ON_BUTTON_REQUEST = 45;
  static readonly ID_DELETE_ALL_ERRORS = 114;

  // Running Method
  static readonly ID_RUNNING_METHOD = 6;

  // Timers
  static readonly ID_CAUTION_WARNING_TIME = 9;
  static readonly ID_START_WARNING_TIME = 12;

  // Safety Features
  static readonly ID_SAFETY_FEATURE_TERMINAL_1 = 300;
  static readonly ID_SAFETY_FEATURE_TERMINAL_2 = 301;
  static readonly ID_SAFETY_FEATURE_TERMINAL_3 = 302;
  static readonly ID_SAFETY_FEATURE_TERMINAL_4 = 303;
  static readonly ID_SAFETY_FEATURE_ELEMENT_AWG_1 = 310;
  static readonly ID_SAFETY_FEATURE_ELEMENT_AWG_2 = 311;
  static readonly ID_SAFETY_FEATURE_ELEMENT_AWG_3 = 312;

  // Maintenance
  static readonly ID_NUMBER_OF_DOOR_CYCLES_TO_MAINTENANCE = 64;
  static readonly ID_DIRECTION_CHANGE_TO_MAINTENANCE = 66;

  // Errors
  static readonly ID_ERROR_SAFETY_ELEMEMT_1_TRIGGERED = 5000;
  static readonly ID_ERROR_RUN_IN_NEEDED = 5001;
  static readonly ID_ERROR_SAFETY_EDGE_NEEDED = 5002;
  static readonly ID_ERROR_AWG_ROTATION_DIRECTION = 5004;
  static readonly ID_ERROR_AWG = 5005;
  static readonly ID_ERROR_MECH_ENDSCHALTER = 5006;
  static readonly ID_ERROR_MOTOR = 5007;
  static readonly ID_ERROR_WDG = 5008;
  static readonly ID_AWG_SAFETY_ELEMENT_NEEDED = 5009;
  static readonly ID_ERROR_INVALID_ENDPOSITIONS = 5011;
  static readonly ID_ERROR_SAFETY_ELEMEMT_7_TRIGGERED = 5012;
  static readonly ID_ERROR_SAFETY_ELEMEMT_8_TRIGGERED = 5013;
  static readonly ID_ERROR_SAFETY_ELEMEMT_9_TRIGGERED = 5014;
  static readonly ID_ERROR_SAFETY_ELEMEMT_2_TRIGGERED = 5018;
  static readonly ID_ERROR_BREAK_TRIGGERED = 5019;
  static readonly ID_ERROR_SAFETY_ELEMEMT_3_TRIGGERED = 5020;
  static readonly ID_ERROR_SAFETY_ELEMEMT_4_TRIGGERED = 5021;
  static readonly ID_ERROR_AWG_NOT_TURNING = 5022;
  static readonly ID_ERROR_BUTTON_DEFECT = 5026;
  static readonly ID_ERROR_RUNTIME = 5027;
  static readonly ID_ERROR_WICKET_DOOR_LOCK = 5028;
  static readonly ID_ERROR_AC_FORCE = 5029;
  static readonly ID_ERROR_GPIO_MECH_END_OPEN = 5034;
  static readonly ID_ERROR_GPIO_MECH_END_CLOSE = 5035;
  static readonly ID_ERROR_GPIO_UP_PCB = 5036;
  static readonly ID_ERROR_GPIO_DOWN_PCB = 5037;
  static readonly ID_ERROR_GPIO_STOP_PCB = 5038;
  static readonly ID_ERROR_GPIO_UP_EXTERN = 5039;
  static readonly ID_ERROR_GPIO_DOWN_EXTERN = 5040;
  static readonly ID_ERROR_GPIO_STOP_EXTERN = 5041;
  static readonly ID_ERROR_GPIO_UP_CSI = 5043;
  static readonly ID_ERROR_GPIO_DOWN_CSI = 5044;
  static readonly ID_ERROR_GPIO_STOP_CSI = 5045;
  static readonly ID_ERROR_DW_TESTING = 5046;
  static readonly ID_ERROR_GPIO_UP_COVER = 5047;
  static readonly ID_ERROR_GPIO_DOWN_COVER = 5048;
  static readonly ID_ERROR_GPIO_STOP_COVER = 5049;
  static readonly ID_ERROR_DEBUG1 = 5051;
  static readonly ID_ERROR_DEBUG2 = 5052;
  static readonly ID_ERROR_DEBUG3 = 5053;
  static readonly ID_ERROR_DEBUG4 = 5054;
  static readonly ID_ERROR_DEBUG5 = 5055;
  static readonly ID_ERROR_DEBUG6 = 5056;
  static readonly ID_ERROR_DEBUG7 = 5057;
  static readonly ID_ERROR_DEBUG8 = 5058;
  static readonly ID_ERROR_DEBUG9 = 5059;
  static readonly ID_ERROR_DEBUG10 = 5060;
  static readonly ID_ERROR_SAFETY_CHAIN_COVER_XB6 = 5061;
  static readonly ID_ERROR_SAFETY_CHAIN_MOTOR_B1_B2 = 5062;
  static readonly ID_ERROR_SAFETY_CHAIN_XB3_4 = 5063;
  static readonly ID_ERROR_SAFETY_CHAIN_MECH_ENDSWITCHES = 5064;
  static readonly ID_ERROR_24V_SUPPLY = 5065;
  static readonly ID_ERROR_24V_SUPPLY_2 = 5066;
  static readonly ID_ERROR_BUS_SAFETY_ELEMEMT_1_TRIGGERED = 5500;
  static readonly ID_ERROR_BUS_SAFETY_ELEMEMT_2_TRIGGERED = 5502;
  static readonly ID_ERROR_BUS_SAFETY_ELEMEMT_3_TRIGGERED = 5505;

  // Prog In
  static readonly IMPULSE_PROG_INPUT_SET_VALUE = 129;
  static readonly BREAK_SURVEILLANCE_SET_VALUE = 136;

  // Prog Output
  static readonly GET_PROG_OUTPUT_STATE_1 = 49;
  static readonly GET_PROG_OUTPUT_STATE_2 = 50;
  static readonly GET_PROG_OUTPUT_STATE_3 = 51;
  static readonly GET_PROG_OUTPUT_STATE_4 = 42;

  static readonly SET_PROG_OUTPUT_STATE_1 = 52;
  static readonly SET_PROG_OUTPUT_STATE_2 = 53;
  static readonly SET_PROG_OUTPUT_STATE_3 = 54;
  static readonly SET_PROG_OUTPUT_STATE_4 = 17;

  // installation
  static readonly ID_MECHANICAL_END_POSITION_RESET = 18;
  static readonly ID_AWG_TOLERANCE = 41;
  static readonly ID_MECHANICAL_END_POSITION_RUNTIME = 105;
  static readonly ID_SP_DEACTIVATION_OPEN = 138;
  static readonly ID_SP_DEACTIVATION_CLOSE = 139;

  static readonly ID_LS_POINT = 146;
  static readonly ID_SAFETY_EDGE_TEST_POINT = 147;

  // Misc defines
  static readonly MOVE_COMMAND_INTERVAL_MS = 500;
  static readonly END_POSITION_FINE_SETTINGS_MIN = 0;
  static readonly END_POSITION_FINE_SETTINGS_MAX = 2000;

  static readonly DEFAULT_READ_MAX_TRY_COUNT = 3;
  static readonly DEFAULT_WRITE_MAX_TRY_COUNT = 1;
  static readonly IMPORTANT_WRITE_MAX_TRY_COUNT = 3;

  static readonly DEBUG = true;

  static readonly TIMEOUT_WRITE_TO_ID = 1000;

  private moveTimer: ReturnType<typeof setTimeout>;
  private moveTimerFunc: () => void;
  private moveType: MoveTypeEnum;
  private moveState: MoveStateEnum = MoveStateEnum.stopped;

  private motorStateChangedFunc = (state: MotorStateEnum[]) => {};
  private currentMotorState: MotorStateEnum = MotorStateEnum._unknown;

  updateOnId: BehaviorSubject<DeviceResponse | null> =
    new BehaviorSubject<DeviceResponse | null>(null);

  updateOnDisconnect: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(null);

  constructor(
    router: Router,
    private readonly http: HttpClient,
    private readonly comm: AbstractMcpDeviceCommService
  ) {
    super(router);

    this.comm.setDebugLogCallback(this.onDebugLog.bind(this));
    this.comm.setReceiveResponseCallback(this.onReceiveResponse.bind(this));
  }

  //
  // Connection methods
  //

  clbk_connect(): void {
    console.log('>>> clbk_connect');

    this.comm
      .connect(
        this.onReceiveUpdate.bind(this),
        this.onConnectionLost.bind(this)
      )
      .then(() => {
        console.log('Connected');
        this.setConnected(true);
      })
      .catch((error: any) => {
        console.error('Connect failed: ' + String(error));
        this.clbk_connectionlost();
      });
  }

  clbk_disconnect(): void {
    console.log('>>> clbk_disconnect');

    this.comm
      .disconnect()
      .then(() => {
        console.log('Disconnected');
      })
      .catch((error: any) => {
        console.error('Disconnect failed (ignoring): ' + String(error));
      })
      .finally(() => {
        this.updateOnDisconnect.next(true);
        this.setConnected(false);
      });
  }

  async registerIds(ids: number[]) {
    try {
      console.log('Successfully registered ID(s)');
      return await this.comm.registerId(ids);
    } catch (error) {
      console.error('Registering ID(s) failed: ' + String(error));
      throwError(() => error);
    }
  }

  unregisterIds(ids: number[]) {
    this.comm
      .unregisterId(ids)
      .then(() => {
        console.log('Successfully unregistered ID(s)');
      })
      .catch((error: any) => {
        console.error('Unregistering ID(s) failed: ' + String(error));
      });
  }

  //
  // Informations readout methods
  //

  clbk_start_informations_reading() {
    console.log('>>> clbk_start_informations_reading');
    this.clearInfo();

    this.comm
      .registerId([
        McpDeviceService.ID_HARDWARE_VERSION,
        McpDeviceService.ID_VERSION_SAFETY_SW,
        McpDeviceService.ID_VERSION_COMFORT_SW,
        McpDeviceService.ID_SERIAL_NUMBER,
        McpDeviceService.ID_DEVICE_ID,
        McpDeviceService.ID_DEVICE_NAME,
        McpDeviceService.ID_DOOR_CYCLE_CTR,
        McpDeviceService.ID_POWER_ON_HOURS,
        McpDeviceService.ID_DOOR_RUN_TIMER_TOTAL,
        McpDeviceService.ID_CURRENT_DOOR_POSITION_IN_PERCENT,
        McpDeviceService.ID_CURRENT_VALUE_AWG,
        McpDeviceService.ID_DOOR_LEARN_STATE
      ])
      .then(() => {
        console.log('Successfully registered ID(s)');
      })
      .catch((error: any) => {
        console.error('Registering ID(s) failed: ' + String(error));
      });

    return this.getDeviceInfo();
  }

  clbk_stop_informations_reading(): void {
    console.log('>>> clbk_stop_informations_reading');

    this.comm
      .unregisterId([
        McpDeviceService.ID_HARDWARE_VERSION,
        McpDeviceService.ID_VERSION_SAFETY_SW,
        McpDeviceService.ID_VERSION_COMFORT_SW,
        McpDeviceService.ID_SERIAL_NUMBER,
        McpDeviceService.ID_DEVICE_ID,
        McpDeviceService.ID_DEVICE_NAME,
        McpDeviceService.ID_DOOR_CYCLE_CTR,
        McpDeviceService.ID_POWER_ON_HOURS,
        McpDeviceService.ID_DOOR_RUN_TIMER_TOTAL,
        McpDeviceService.ID_CURRENT_DOOR_POSITION_IN_PERCENT,
        McpDeviceService.ID_CURRENT_VALUE_AWG,
        McpDeviceService.ID_DOOR_LEARN_STATE
      ])
      .then(() => {
        console.log('Successfully unregistered ID(s)');
      })
      .catch((error: any) => {
        console.error('Unregistering ID(s) failed:', error);
      });
  }

  clbk_upload_informations_reading(): void {
    console.log('>>> clbk_upload_informations_reading');
    this.setIsUploading(true);

    this.collect_upload_informations()
      .then(async (data: any) => {
        console.log('Infos:', data);

        const headers = new HttpHeaders()
          .set('content-type', 'application/json; charset=UTF-8')
          .set('x-api-key', McpDeviceService.INFO_READOUT_POST_API_KEY)
          .set('x-client-id', McpDeviceService.INFO_READOUT_POST_CLIENT_ID);

        return await firstValueFrom(
          this.http
            .post<any>(
              McpDeviceService.INFO_READOUT_POST_URL,
              JSON.stringify(data),
              { headers: headers }
            )
            .pipe(
              catchError((err: HttpErrorResponse) => {
                throw new McpDeviceError(
                  err.message,
                  McpDeviceError.BASE_CLOUD_UPLOAD + Number(err.status)
                );
              })
            )
        );
      })
      .then((res) => {
        console.log('Upload infos result: ', res);
        if (String(res.code) !== '200') {
          throw new McpDeviceError(
            'Failed to upload infos with error ' + String(res.error),
            McpDeviceError.BASE_CLOUD_UPLOAD + Number(res.error)
          );
        }
        this.setIsFinished(true);
      })
      .catch((error) => {
        console.error('Failed to collect/upload infos: ' + String(error));
        this.showErrorCode(error);
      })
      .finally(() => {
        this.setIsUploading(false);
      });
  }

  //
  // Setup positions methods
  //

  clbk_getfromid(id: number): Observable<any[]> {
    console.log('>>> clbk_getfromid');

    return from(this.comm.readId(id));
  }

  clbk_writetoid(id: number, value: number | number[]): Observable<any[]> {
    console.log('>>> clbk_writetoid');

    return from(this.comm.writeId(id, value, McpDeviceService.TIMEOUT_WRITE_TO_ID));
  }

  // private async readFromId(
  //   id: number,
  //   maxTryCount: number = McpDeviceService.DEFAULT_READ_MAX_TRY_COUNT
  // ): Promise<DoorLearnStateEnum> {
  //   let tryCount = 0;
  //   maxTryCount = 1;
  //   while (true) {
  //     tryCount++;
  //     try {
  //       const values = await this.comm.readId(id);
  //       const result = values[0];
  //       console.log('>>> readFromId', result);
  //       return result;
  //     } catch (error: any) {
  //       if (tryCount >= maxTryCount) {
  //         console.error(
  //           '>>> readFromId failed to read ID ' +
  //             String(id) +
  //             ': ' +
  //             String(error)
  //         );
  //         throw error;
  //       }
  //     }
  //   }
  // }

  clbk_getlearningstateendpos(): Observable<boolean> {
    console.log('>>> clbk_getlearningstateendpos');

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();

        const result = dls === DoorLearnStateEnum.learned;
        console.log('clbk_getlearningstateendpos result=' + String(result));

        return result;
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_getpreconditionsstatefinetuning(): Observable<boolean> {
    console.log('>>> clbk_getpreconditionsstatefinetuning');

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();
        let result = dls === DoorLearnStateEnum.learned;

        if (result) {
          const ept = await this.readEndPositionType();
          result = ept === EndPositionTypeEnum.awg;
        }

        console.log(
          'clbk_getpreconditionsstatefinetuning result=' + String(result)
        );

        return result;
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  //
  // Setup end positions methods
  //

  clbk_initiateendpossetup(): Observable<void> {
    console.log('>>> clbk_initiateendpossetup');

    if (McpDeviceService.DEBUG) {
      this.comm.registerId([
        McpDeviceService.ID_CURRENT_VALUE_AWG,
        McpDeviceService.ID_DOOR_LEARN_STATE
      ]);
    }

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();
        if (dls === DoorLearnStateEnum.learning) {
          await this.writeLearningCommand(
            LearningCommandEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        } else if (dls === DoorLearnStateEnum.imeLearning) {
          await this.writeLearningImePosition(
            LearningImePositionEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        }

        await this.writeEndPositionType(
          EndPositionTypeEnum.awg,
          McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        );

        await this.writeLearningCommand(LearningCommandEnum.requestLearning);
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  private f_ep_setup_abort_generic(): Observable<void> {
    this.stopMove();
    return from(
      this.writeLearningCommand(
        LearningCommandEnum.abortLearning,
        McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
      ).catch(() => undefined)
    );
  }

  f_ep_setup_abort(): void {
    console.log('>>> f_ep_setup_abort');
    this.f_ep_setup_abort_generic();
  }

  clbk_getswitchposition(): Observable<boolean> {
    console.log('>>> clbk_getswitchposition');

    return from(
      (async () => {
        const rf = await this.readRotatingField();

        return rf === RotatingFieldEnum.reversedRotating;
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_savedoordirectionswitchposition(position: boolean): Observable<void> {
    console.log(
      '>>> clbk_savedoordirectionswitchposition position=' + String(position)
    );
    return from(
      this.writeRotatingField(
        position
          ? RotatingFieldEnum.reversedRotating
          : RotatingFieldEnum.default,
        McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
      )
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  private stopMove(): void {
    console.log('>>> stopMove moveState=' + String(this.moveState));
    if (this.moveState === MoveStateEnum.running) {
      this.moveState = MoveStateEnum.stopping;
      clearTimeout(this.moveTimer);

      // send drive-stop
      switch (this.moveType) {
        case MoveTypeEnum.endpos:
          this.writeLearningCommand(
            LearningCommandEnum.driveStop,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          )
            .catch(() => undefined)
            .finally(() => {
              this.moveState = MoveStateEnum.stopped;
            });
          break;

        case MoveTypeEnum.intermediatepos:
          this.writeLearningImePosition(
            LearningImePositionEnum.stopDriving,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          )
            .catch(() => undefined)
            .finally(() => {
              this.moveState = MoveStateEnum.stopped;
            });
          break;
      }
    }
  }

  private clbk_ep_move_generic(
    buttonType: ButtonTypeEnum,
    buttonEvent: ButtonEventEnum,
    reverseDirection: boolean
  ): void {
    if (buttonEvent === ButtonEventEnum.buttonpressed) {
      if (this.moveState !== MoveStateEnum.stopped) {
        console.warn(
          'another move operation is in progress (moveState=' +
            this.moveState +
            '), ignoring'
        );
        return;
      }

      // start/change cyclical sending of drive-open/close learning commands
      this.moveType = MoveTypeEnum.endpos;
      this.moveState = MoveStateEnum.running;

      this.moveTimerFunc = () => {
        console.log('>>> clbk_ep_move_generic cyclic buttonType=' + buttonType);
        this.writeLearningCommand(
          buttonType === ButtonTypeEnum.buttonup
            ? LearningCommandEnum.driveOpen
            : LearningCommandEnum.driveClose
        )
          .then(() => {
            if (this.moveState === MoveStateEnum.running) {
              this.moveTimer = setTimeout(() => {
                this.moveTimerFunc();
              }, McpDeviceService.MOVE_COMMAND_INTERVAL_MS);
            }
          })
          .catch((error) => {
            this.stopMove();
            this.showErrorCode(error);
          });
      };

      this.moveTimerFunc();
    } else {
      // stop cyclical sending of drive-open/close learning commands and send stop
      this.stopMove();
    }
  }

  clbk_epo_move(
    buttonType: ButtonTypeEnum,
    buttonEvent: ButtonEventEnum,
    reverseDirection: boolean
  ): void {
    console.log(
      '>>> clbk_epo_move buttonType=' +
        String(buttonType) +
        ', buttonEvent=' +
        String(buttonEvent) +
        ', reverseDirection=' +
        String(reverseDirection)
    );

    this.clbk_ep_move_generic(buttonType, buttonEvent, reverseDirection);
  }

  clbk_epo_save(): Observable<void> {
    console.log('>>> clbk_epo_save');

    this.stopMove();
    return from(
      this.writeLearningCommand(
        LearningCommandEnum.saveOpen,
        McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
      )
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_epc_move(
    buttonType: ButtonTypeEnum,
    buttonEvent: ButtonEventEnum,
    reverseDirection: boolean
  ): void {
    console.log(
      '>>> clbk_epc_move buttonType=' +
        String(buttonType) +
        ', buttonEvent=' +
        String(buttonEvent) +
        ', reverseDirection=' +
        String(reverseDirection)
    );

    this.clbk_ep_move_generic(buttonType, buttonEvent, reverseDirection);
  }

  clbk_epc_save_and_calibrate(): Observable<void> {
    console.log('>>> clbk_epc_save_and_calibrate');

    this.stopMove();

    return from(
      (async () => {
        await this.writeLearningCommand(
          LearningCommandEnum.saveClose,
          McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        );

        await this.clbk_approach_generic(
          ImpulsTargetEnum.endPositionOpen,
          this.clbk_epoready.bind(this),
          undefined
        );
        await firstValueFrom(this.getEndPositionOpenedReady());

        await this.clbk_approach_generic(
          ImpulsTargetEnum.endPositionClose,
          this.clbk_epcready.bind(this),
          undefined
        );
        await firstValueFrom(this.getEndPositionClosedReady());

        console.log('clbk_epc_save_and_calibrate succeeded');
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_epc_save(): Observable<void> {
    console.log('>>> clbk_epc_save');

    this.stopMove();

    return from(
      (async () => {
        await this.writeLearningCommand(
          LearningCommandEnum.saveClose,
          McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        );
        console.log('clbk_epc_save succeeded');
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_initiateendposopenedchange(): Observable<void> {
    console.log('>>> clbk_initiateendposopenedchange');

    if (McpDeviceService.DEBUG) {
      this.comm.registerId([
        McpDeviceService.ID_CURRENT_VALUE_AWG,
        McpDeviceService.ID_DOOR_LEARN_STATE
      ]);
    }

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();
        if (dls === DoorLearnStateEnum.unlearned) {
          throw new McpDeviceError(
            'Door state is unlearned',
            McpDeviceError.ERROR_DOOR_STATE_UNLEARNED
          );
        } else if (dls === DoorLearnStateEnum.learning) {
          await this.writeLearningCommand(
            LearningCommandEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        } else if (dls === DoorLearnStateEnum.imeLearning) {
          await this.writeLearningImePosition(
            LearningImePositionEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        }

        await this.writeLearningCommand(LearningCommandEnum.requestLearning);
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  f_epo_change_abort(): void {
    console.log('>>> f_epo_change_abort');
    this.f_ep_setup_abort_generic();
  }

  clbk_epo_save_and_calibrate(): Observable<void> {
    console.log('>>> clbk_epo_save_and_calibrate');

    this.stopMove();

    return from(
      (async () => {
        await this.writeLearningCommand(
          LearningCommandEnum.saveOpen,
          McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        );
        await this.writeLearningCommand(
          LearningCommandEnum.skipClose,
          McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        );

        await this.clbk_approach_generic(
          ImpulsTargetEnum.endPositionClose,
          this.clbk_epcready.bind(this),
          undefined
        );
        await firstValueFrom(this.getEndPositionClosedReady());

        await this.clbk_approach_generic(
          ImpulsTargetEnum.endPositionOpen,
          this.clbk_epoready.bind(this),
          undefined
        );
        await firstValueFrom(this.getEndPositionOpenedReady());

        // currently MCP will only accept a runIn-test from open to close it seems *sigh*..
        await this.clbk_approach_generic(
          ImpulsTargetEnum.endPositionClose,
          this.clbk_epcready.bind(this),
          undefined
        );
        await firstValueFrom(this.getEndPositionClosedReady());

        console.log('clbk_epo_save_and_calibrate succeeded');
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_initiateendposclosedchange(): Observable<void> {
    console.log('>>> clbk_initiateendposclosedchange');

    if (McpDeviceService.DEBUG) {
      this.comm.registerId([
        McpDeviceService.ID_CURRENT_VALUE_AWG,
        McpDeviceService.ID_DOOR_LEARN_STATE
      ]);
    }

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();
        if (dls === DoorLearnStateEnum.unlearned) {
          throw new McpDeviceError(
            'Door state is unlearned',
            McpDeviceError.ERROR_DOOR_STATE_UNLEARNED
          );
        } else if (dls === DoorLearnStateEnum.learning) {
          await this.writeLearningCommand(
            LearningCommandEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        } else if (dls === DoorLearnStateEnum.imeLearning) {
          await this.writeLearningImePosition(
            LearningImePositionEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        }

        await this.writeLearningCommand(LearningCommandEnum.requestLearning);
        await this.writeLearningCommand(LearningCommandEnum.skipOpen);
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  f_epc_change_abort(): void {
    console.log('>>> f_epc_change_abort');
    this.f_ep_setup_abort_generic();
  }

  //
  // Setup intermediate positions methods
  //

  clbk_ip_deleteintpos(intposopen: boolean): Observable<void> {
    console.log('>>> clbk_ip_deleteintpos intposopen=' + String(intposopen));
    return from(
      this.writeDeleteImePosition(
        intposopen
          ? DeleteImePositionEnum.imePositionOpen
          : DeleteImePositionEnum.imePositionClose,
        McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
      )
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_IP_initiatelrn(): Observable<void> {
    console.log('>>> clbk_IP_initiatelrn');

    if (McpDeviceService.DEBUG) {
      this.comm.registerId([
        McpDeviceService.ID_CURRENT_VALUE_AWG,
        McpDeviceService.ID_DOOR_LEARN_STATE
      ]);
    }

    return from(
      (async () => {
        const dls = await this.readDoorLearnState();
        if (dls === DoorLearnStateEnum.unlearned) {
          throw new McpDeviceError(
            'Door state is unlearned',
            McpDeviceError.ERROR_DOOR_STATE_UNLEARNED
          );
        } else if (dls === DoorLearnStateEnum.learning) {
          await this.writeLearningCommand(
            LearningCommandEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        } else if (dls === DoorLearnStateEnum.imeLearning) {
          await this.writeLearningImePosition(
            LearningImePositionEnum.abortLearning,
            McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
          );
        }

        await this.writeLearningImePosition(
          LearningImePositionEnum.requestLearning
        );
      })()
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_IP_abortlrn(): void {
    console.log('>>> clbk_IP_abortlrn');
    this.stopMove();
    this.writeLearningImePosition(
      LearningImePositionEnum.abortLearning,
      McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
    ).catch(() => undefined);
  }

  clbk_ips_move(
    buttonType: ButtonTypeEnum,
    buttonEvent: ButtonEventEnum
  ): void {
    console.log(
      '>>> clbk_ips_move buttonType=' +
        buttonType +
        ', buttonEvent=' +
        buttonEvent
    );

    if (buttonEvent === ButtonEventEnum.buttonpressed) {
      if (this.moveState !== MoveStateEnum.stopped) {
        console.warn(
          'another move operation is in progress (moveState=' +
            this.moveState +
            '), ignoring'
        );
        return;
      }

      // start/change cyclical sending of drive-open/close learning commands
      this.moveType = MoveTypeEnum.intermediatepos;
      this.moveState = MoveStateEnum.running;

      this.moveTimerFunc = () => {
        console.log('>>> clbk_ips_move cyclic buttonType=' + buttonType);
        this.writeLearningImePosition(
          buttonType === ButtonTypeEnum.buttonup
            ? LearningImePositionEnum.openDriving
            : LearningImePositionEnum.closeDriving
        )
          .then(() => {
            if (this.moveState === MoveStateEnum.running) {
              this.moveTimer = setTimeout(() => {
                this.moveTimerFunc();
              }, McpDeviceService.MOVE_COMMAND_INTERVAL_MS);
            }
          })
          .catch((error) => {
            this.stopMove();
            this.showErrorCode(error);
          });
      };

      this.moveTimerFunc();
    } else {
      // stop cyclical sending of drive-open/close learning commands and send stop
      this.stopMove();
    }
  }

  clbk_ipo_save(): Observable<void> {
    console.log('>>> clbk_ipo_save');

    this.stopMove();
    return from(
      this.writeLearningImePosition(LearningImePositionEnum.saveImeOpen)
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_ipc_save(): Observable<void> {
    console.log('>>> clbk_ipc_save');

    this.stopMove();
    return from(
      this.writeLearningImePosition(LearningImePositionEnum.saveImeClose)
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  //
  // Testing positions methods
  //

  clbk_ispositionsaved(positiontype: PositionTypeEnum): Observable<boolean> {
    console.log('>>> clbk_ispositionsaved positiontype=' + positiontype);

    if (
      positiontype === PositionTypeEnum.epo ||
      positiontype === PositionTypeEnum.epc
    ) {
      return from(
        this.readDoorLearnState().then((dls: DoorLearnStateEnum) => {
          return dls === DoorLearnStateEnum.learned;
        })
      ).pipe(catchError(this.throwErrorCode.bind(this)));
    } else {
      // ipo/ipc
      return from(
        this.readIntermediatePositions().then((ip: number[]) => {
          return ip[positiontype === PositionTypeEnum.ipo ? 0 : 1] !== 0;
        })
      ).pipe(catchError(this.throwErrorCode.bind(this)));
    }
  }

  private clearMotorStateChangedFunc(): void {
    this.motorStateChangedFunc = (state: MotorStateEnum[]) => {};
    this.currentMotorState = MotorStateEnum._unknown;
  }

  private async clbk_approach_generic(
    impulsTarget: ImpulsTargetEnum,
    callbackStop: any,
    callbackMoving: any
  ): Promise<void> {
    if (McpDeviceService.DEBUG) {
      this.comm.registerId([
        McpDeviceService.ID_CURRENT_VALUE_AWG,
        McpDeviceService.ID_DOOR_LEARN_STATE
      ]);
    }

    this.clearMotorStateChangedFunc();

    let checkCurrentDoorPosition: CurrentDoorPositionEnum | null = null;

    switch (impulsTarget) {
      case ImpulsTargetEnum.endPositionOpen:
        checkCurrentDoorPosition = CurrentDoorPositionEnum.endPositionOpen;
        break;

      case ImpulsTargetEnum.endPositionClose:
        checkCurrentDoorPosition = CurrentDoorPositionEnum.endPositionClose;
        break;

      case ImpulsTargetEnum.imePositionOpen:
        checkCurrentDoorPosition = CurrentDoorPositionEnum.intermediateOpen;
        break;

      case ImpulsTargetEnum.imePositionClose:
        checkCurrentDoorPosition = CurrentDoorPositionEnum.intermediateClose;
        break;
    }

    this.motorStateChangedFunc = (state: MotorStateEnum[]) => {
      const oldState: MotorStateEnum = this.currentMotorState;

      // the initial motorstate always needs to begin with stop, we ignore any noise before that
      // else we can't properly detect the desired state changes when door was already moving
      if (
        oldState !== MotorStateEnum._unknown ||
        state[0] === MotorStateEnum.stop
      ) {
        this.currentMotorState = state[0];
      }

      if (oldState === MotorStateEnum._unknown || oldState === state[0]) {
        // we are only interested in well-defined state changes, ignore
        return;
      }

      if (
        (oldState === MotorStateEnum.open ||
          oldState === MotorStateEnum.close) &&
        state[0] === MotorStateEnum.stop
      ) {
        // open/close -> stop
        console.log(
          'clbk_approach_generic motor state changed from open/close to stop'
        );
        this.clearMotorStateChangedFunc();
        this.comm
          .unregisterId(McpDeviceService.ID_MOTOR_STATE)
          .catch(() => undefined); // we just try to unregister and don't wait
        callbackStop();
      } else if (
        oldState === MotorStateEnum.stop &&
        (state[0] === MotorStateEnum.open || state[0] === MotorStateEnum.close)
      ) {
        // stop -> open/close
        console.log(
          'clbk_approach_generic motor state changed from stop to open/close'
        );
        if (callbackMoving !== undefined) {
          callbackMoving();
        }
      }
    };

    return await this.comm
      .unregisterId(McpDeviceService.ID_MOTOR_STATE) // make sure motor state is unregistered so we definitiely get the initial value when re-registering
      .then(async () => {
        // we really want to always start with a well-defined stop state because the door could currently be moving already in either
        // the correct direction (no stop of motor happens in between then) or the opposite direction (a stop happens in between then)
        // else its impossible to properly detect the desired state changes and not misinterpret them
        await this.writeImpulsTarget(ImpulsTargetEnum.positionStop);

        await this.comm.registerId(McpDeviceService.ID_MOTOR_STATE);

        // should we test if we are already at the desired position without sending the impuls target?
        let cdp = CurrentDoorPositionEnum._unknown;
        if (checkCurrentDoorPosition != null) {
          cdp = await this.readCurrentDoorPosition();
        }

        // already at desired position?
        if (
          checkCurrentDoorPosition != null &&
          cdp === checkCurrentDoorPosition
        ) {
          console.log(
            'clbk_approach_generic current door position is already at desired position'
          );
          this.clearMotorStateChangedFunc();
          this.comm.unregisterId(McpDeviceService.ID_MOTOR_STATE); // we just try to unregister and don't wait
          callbackStop();
        } else {
          await this.writeImpulsTarget(impulsTarget);
        }
      })
      .catch((error: any) => {
        this.clearMotorStateChangedFunc();
        this.comm.unregisterId(McpDeviceService.ID_MOTOR_STATE); // we just try to unregister and don't wait
        console.error('clbk_approach_generic failed: ' + String(error));

        // we can be called either with noone waiting for us (but observing showerror), or waiting for us and reacting to throw
        this.showErrorCode(error);
        this.throwErrorCode(error);
      });
  }

  clbk_approach_epo(): void {
    console.log('>>> clbk_approach_epo');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionOpen,
      this.clbk_epoready.bind(this),
      undefined
    );
  }

  clbk_approach_epc(): void {
    console.log('>>> clbk_approach_epc');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionClose,
      this.clbk_epcready.bind(this),
      undefined
    );
  }

  clbk_approach_ipo(): void {
    console.log('>>> clbk_approach_ipo');
    this.clbk_approach_generic(
      ImpulsTargetEnum.imePositionOpen,
      this.clbk_ipoready.bind(this),
      undefined
    );
  }

  clbk_approach_ipc(): void {
    console.log('>>> clbk_approach_ipc');
    this.clbk_approach_generic(
      ImpulsTargetEnum.imePositionClose,
      this.clbk_ipcready.bind(this),
      undefined
    );
  }

  mcp_stop_movement(): void {
    console.log('>>> mcp_stop_movement');
    this.clearMotorStateChangedFunc();
    this.writeImpulsTarget(
      ImpulsTargetEnum.positionStop,
      McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
    );
    this.comm.unregisterId(McpDeviceService.ID_MOTOR_STATE); // we just try to unregister and don't wait

    // this is just to make sure the UI doesn't stay in disabled state (if any)
    this.clbk_fto_is_door_moving(false);
  }

  //
  // Finetuning positions methods
  //

  private async get_ftp_ep_generic(open: boolean): Promise<number> {
    return await this.readDiagnosisEndPositionFineSettings().then(
      (epfs: number[]) => {
        return epfs[open ? 0 : 1];
      }
    );
  }

  clbk_get_ftp_epo(): Observable<number> {
    console.log('>>> clbk_get_ftp_epo');
    return from(this.get_ftp_ep_generic(true)).pipe(
      catchError(this.throwErrorCode.bind(this))
    );
  }

  clbk_get_ftp_epc(): Observable<number> {
    console.log('>>> clbk_get_ftp_epc');
    return from(this.get_ftp_ep_generic(false)).pipe(
      catchError(this.throwErrorCode.bind(this))
    );
  }

  private save_ftp_generic(open: boolean, value: number): Observable<void> {
    return from(
      this.get_ftp_ep_generic(!open).then(async (value_other) => {
        return await this.writeDiagnosisEndPositionFineSettings(
          open ? [value, value_other] : [value_other, value],
          McpDeviceService.IMPORTANT_WRITE_MAX_TRY_COUNT
        );
      })
    ).pipe(catchError(this.throwErrorCode.bind(this)));
  }

  clbk_fto_save(ftp_epo: number): Observable<void> {
    console.log('>>> clbk_fto_save ftp_epo=', ftp_epo);
    return this.save_ftp_generic(true, ftp_epo);
  }

  clbk_ftc_save(ftp_epc: number): Observable<void> {
    console.log('>>> clbk_ftc_save ftp_epc=', ftp_epc);
    return this.save_ftp_generic(false, ftp_epc);
  }

  clbk_fto_epo_approach(): void {
    console.log('>>> clbk_fto_epo_approach');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionOpen,
      this.clbk_fto_approach_door_stopped.bind(this),
      this.clbk_fto_approach_door_moving.bind(this)
    );
  }

  clbk_fto_epc_approach(): void {
    console.log('>>> clbk_fto_epc_approach');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionClose,
      this.clbk_fto_approach_door_stopped.bind(this),
      this.clbk_fto_approach_door_moving.bind(this)
    );
  }

  private clbk_fto_approach_door_moving(): void {
    this.clbk_fto_is_door_moving(true);
  }

  private clbk_fto_approach_door_stopped(): void {
    this.clbk_fto_is_door_moving(false);
  }

  clbk_ftc_epo_approach(): void {
    console.log('>>> clbk_ftc_epo_approach');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionOpen,
      this.clbk_ftc_approach_door_stopped.bind(this),
      this.clbk_ftc_approach_door_moving.bind(this)
    );
  }

  clbk_ftc_epc_approach(): void {
    console.log('>>> clbk_ftc_epc_approach');
    this.clbk_approach_generic(
      ImpulsTargetEnum.endPositionClose,
      this.clbk_ftc_approach_door_stopped.bind(this),
      this.clbk_ftc_approach_door_moving.bind(this)
    );
  }

  private clbk_ftc_approach_door_moving(): void {
    this.clbk_ftc_is_door_moving(true);
  }

  private clbk_ftc_approach_door_stopped(): void {
    this.clbk_ftc_is_door_moving(false);
  }

  //
  // Internal helper methods
  //

  private throwErrorCode(error: any): never {
    if (!(error instanceof McpDeviceError)) {
      error = new McpDeviceError(error.message, McpDeviceError.ERROR_UNKNOWN);
    }

    throw error.code;
  }

  private showErrorCode(error: any): void {
    if (!(error instanceof McpDeviceError)) {
      error = new McpDeviceError(error.message, McpDeviceError.ERROR_UNKNOWN);
    }

    this.clbk_show_error(error.code);
  }

  protected onConnectionLost() {
    console.warn('Connection lost');
    this.updateOnDisconnect.next(true);
    this.setConnected(false);
    this.clbk_connectionlost();
  }

  protected onReceiveUpdate(json: DeviceResponse) {
    if (json) {
      this.updateOnId.next(json);
    }
    if (json.nrc === undefined || json.nrc === 'NULL') {
      const values = json.d;
      console.log(
        'Received update for ID:' + String(json.id) + ' Value(s):',
        values
      );

      switch (json.id) {
        case McpDeviceService.ID_MOTOR_STATE: {
          const msv = [
            values[0] as MotorStateEnum,
            values[1] as MotorStateEnum
          ];
          this.motorStateChangedFunc(msv);
          break;
        }
        case McpDeviceService.ID_HARDWARE_VERSION:
          this.clbk_ir_set_hardwareversion(
            String(values[0])
          );
          break;
        case McpDeviceService.ID_VERSION_SAFETY_SW:
          this.clbk_ir_set_swversionsc(
            String(values[0]) +
              '.' +
              String(values[1]) +
              '.' +
              String(values[2])
          );
          break;
        case McpDeviceService.ID_VERSION_COMFORT_SW:
          this.clbk_ir_set_swversioncc(
            String(values[0]) +
              '.' +
              String(values[1]) +
              '.' +
              String(values[2])
          );
          break;
        case McpDeviceService.ID_DEVICE_ID:
          this.clbk_ir_set_device_id(values[0] as unknown as string);
          break;
        case McpDeviceService.ID_SERIAL_NUMBER:
          this.clbk_ir_set_serialnumber(values[0] as unknown as string);
          break;
        case McpDeviceService.ID_DEVICE_NAME:
          this.clbk_ir_set_device_name(values[0] as unknown as string);
          break;
        case McpDeviceService.ID_DOOR_CYCLE_CTR:
          this.clbk_ir_set_doorcyclecountertotal(values[0].toString());
          break;
        case McpDeviceService.ID_POWER_ON_HOURS:
          this.clbk_ir_set_poweronhours(this.secondsToDhm(values[0]));
          break;
        case McpDeviceService.ID_DOOR_RUN_TIMER_TOTAL:
          this.clbk_ir_set_doorruntimertotal(this.secondsToDhm(values[0]));
          break;
        case McpDeviceService.ID_OPEN_TIME_CTR:
          this.clbk_ir_set_opentimecounter(this.secondsToDhm(values[0]));
          break;
        case McpDeviceService.ID_CURRENT_DOOR_POSITION_IN_PERCENT:
          this.clbk_ir_set_currentdoorposition(values[0].toString());
          break;
        case McpDeviceService.ID_CURRENT_VALUE_AWG:
          this.clbk_ir_set_currentawgvalue(values[0].toString());
          break;
        case McpDeviceService.ID_DOOR_LEARN_STATE:
          this.clbk_ir_set_doorlearnstate(DoorLearnStateEnum[values[0]]);
          break;
      }
    } else {
      console.error(
        'Received update error for ID:' +
          String(json.id) +
          ' Error:' +
          String(json.nrc)
      );
      const err = '(Error ' + String(json.nrc) + ')';

      switch (json.id) {
        case McpDeviceService.ID_HARDWARE_VERSION:
          this.clbk_ir_set_hardwareversion(err);
          break;
        case McpDeviceService.ID_VERSION_SAFETY_SW:
          this.clbk_ir_set_swversionsc(err);
          break;
        case McpDeviceService.ID_VERSION_COMFORT_SW:
          this.clbk_ir_set_swversioncc(err);
          break;
        case McpDeviceService.ID_DEVICE_ID:
          this.clbk_ir_set_device_id(err);
          break;
        case McpDeviceService.ID_SERIAL_NUMBER:
          this.clbk_ir_set_serialnumber(err);
          break;
        case McpDeviceService.ID_DEVICE_NAME:
          this.clbk_ir_set_device_name(err);
          break;
        case McpDeviceService.ID_DOOR_CYCLE_CTR:
          this.clbk_ir_set_doorcyclecountertotal(err);
          break;
        case McpDeviceService.ID_POWER_ON_HOURS:
          this.clbk_ir_set_poweronhours(err);
          break;
        case McpDeviceService.ID_DOOR_RUN_TIMER_TOTAL:
          this.clbk_ir_set_doorruntimertotal(err);
          break;
        case McpDeviceService.ID_OPEN_TIME_CTR:
          this.clbk_ir_set_opentimecounter(err);
          break;
        case McpDeviceService.ID_CURRENT_DOOR_POSITION_IN_PERCENT:
          this.clbk_ir_set_currentdoorposition(err);
          break;
        case McpDeviceService.ID_CURRENT_VALUE_AWG:
          this.clbk_ir_set_currentawgvalue(err);
          break;
        case McpDeviceService.ID_DOOR_LEARN_STATE:
          this.clbk_ir_set_doorlearnstate(err);
          break;
      }
    }
  }

  protected onDebugLog(message: any) {
    console.log('McpDebug:', message);
  }

  protected onReceiveResponse(json: any) {
    // filter out update responses (we don't want duplicate debug prints for them)
    if (json.res !== 'u') {
      console.log('Received response:', json);
    }
  }

  private async collect_upload_informations(): Promise<any> {
    const data: any = {
      schemaId: 'InformationReadoutV1',
      TimeStampAcquisition: new Date().toISOString()
    };

    try {
      const values = await this.comm.readId(
        McpDeviceService.ID_DEVICE_ID
      );
      data.deviceId = values[0];
    } catch (error: any) {
      console.error(
        'Failed to read ID ' +
          String(McpDeviceService.ID_DEVICE_ID) +
          ': ' +
          String(error)
      );
    }

    try {
      const values = await this.comm.readId(
        McpDeviceService.ID_SERIAL_NUMBER
      );
      data.serialNumber = values[0];
    } catch (error: any) {
      console.error(
        'Failed to read ID ' +
          String(McpDeviceService.ID_SERIAL_NUMBER) +
          ': ' +
          String(error)
      );
    }

    try {
      const values = await this.comm.readId(
        McpDeviceService.ID_DEVICE_NAME
      );
      data.deviceName = values[0];
    } catch (error: any) {
      console.error(
        'Failed to read ID ' +
          String(McpDeviceService.ID_DEVICE_NAME) +
          ': ' +
          String(error)
      );
    }

    try {
      const values = await this.comm.readId(
        McpDeviceService.ID_HARDWARE_VERSION
      );
      data.hardwareVersion =
        String(values[0]);
    } catch (error: any) {
      console.error(
        'Failed to read ID ' +
          String(McpDeviceService.ID_HARDWARE_VERSION) +
          ': ' +
          String(error)
      );
    }

    try {
      const values = await this.comm.readId(
        McpDeviceService.ID_VERSION_SAFETY_SW
      );
      data.softwareVersionSC =
        String(values[0]) + '.' + String(values[1]) + '.' + String(values[2]);
    } catch (error: any) {
      console.error(
        'Failed to read ID ' +
          String(McpDeviceService.ID_VERSION_SAFETY_SW) +
          ': ' +
          String(error)
      );
    }

    try {
      const values = await this.comm.readId(
        McpDeviceService.ID_VERSION_COMFORT_SW
      );
      data.softwareVersionCC =
        String(values[0]) + '.' + String(values[1]) + '.' + String(values[2]);
    } catch (error: any) {
      console.error(
        'Failed to read ID ' +
          String(McpDeviceService.ID_VERSION_COMFORT_SW) +
          ': ' +
          String(error)
      );
    }

    try {
      const values = await this.comm.readId(McpDeviceService.ID_DOOR_CYCLE_CTR);
      data.doorTotalCycles = values[0];
    } catch (error: any) {
      console.error(
        'Failed to read ID ' +
          String(McpDeviceService.ID_DOOR_CYCLE_CTR) +
          ': ' +
          String(error)
      );
    }

    try {
      const values = await this.comm.readId(McpDeviceService.ID_POWER_ON_HOURS);
      data.powerOnTotalTime = this.secondsToDhm(values[0]);
    } catch (error: any) {
      console.error(
        'Failed to read ID ' +
          String(McpDeviceService.ID_POWER_ON_HOURS) +
          ': ' +
          String(error)
      );
    }

    try {
      const values = await this.comm.readId(
        McpDeviceService.ID_DOOR_RUN_TIMER_TOTAL
      );
      data.runTotalTime = this.secondsToDhm(values[0]);
    } catch (error: any) {
      console.error(
        'Failed to read ID ' +
          String(McpDeviceService.ID_DOOR_RUN_TIMER_TOTAL) +
          ': ' +
          String(error)
      );
    }

    try {
      const values = await this.comm.readId(
        McpDeviceService.ID_CURRENT_DOOR_POSITION_IN_PERCENT
      );
      data.currentPosition = values[0];
    } catch (error: any) {
      console.error(
        'Failed to read ID ' +
          String(McpDeviceService.ID_CURRENT_DOOR_POSITION_IN_PERCENT) +
          ': ' +
          String(error)
      );
    }

    try {
      const values = await this.comm.readId(
        McpDeviceService.ID_CURRENT_VALUE_AWG
      );
      data.currentAWG = values[0];
    } catch (error: any) {
      console.error(
        'Failed to read ID ' +
          String(McpDeviceService.ID_CURRENT_VALUE_AWG) +
          ': ' +
          String(error)
      );
    }

    // sanity check for required fields
    if (
      data.deviceId === undefined ||
      data.deviceId === null ||
      !data.deviceId.length
    ) {
      throw new McpDeviceError(
        'deviceId is empty',
        McpDeviceError.ERROR_REQUIRED_INFO_MISSING
      );
    }
    if (
      data.serialNumber === undefined ||
      data.serialNumber === null ||
      !data.serialNumber.length
    ) {
      throw new McpDeviceError(
        'serialNumber is empty',
        McpDeviceError.ERROR_REQUIRED_INFO_MISSING
      );
    }

    return data;
  }

  private clearInfo(): void {
    this.clbk_ir_set_hardwareversion('');
    this.clbk_ir_set_swversionsc('');
    this.clbk_ir_set_swversioncc('');
    this.clbk_ir_set_serialnumber('');
    this.clbk_ir_set_device_id('');
    this.clbk_ir_set_device_name('');
    this.clbk_ir_set_doorcyclecountertotal('');
    this.clbk_ir_set_poweronhours('');
    this.clbk_ir_set_doorruntimertotal('');
    this.clbk_ir_set_opentimecounter('');
    this.clbk_ir_set_currentdoorposition('');
    this.clbk_ir_set_currentawgvalue('');
    this.clbk_ir_set_doorlearnstate('');
  }

  private secondsToDhm(seconds: number): string {
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);

    return zeroPad(d, 3) + ':' + zeroPad(h, 2) + ':' + zeroPad(m, 2);
  }

  private async readDoorLearnState(
    maxTryCount: number = McpDeviceService.DEFAULT_READ_MAX_TRY_COUNT
  ): Promise<DoorLearnStateEnum> {
    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        const values = await this.comm.readId(
          McpDeviceService.ID_DOOR_LEARN_STATE
        );
        const doorLearnState = values[0] as DoorLearnStateEnum;
        console.log(
          '>>> readDoorLearnState doorLearnState',
          DoorLearnStateEnum[doorLearnState]
        );
        return doorLearnState;
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '>>> readDoorLearnState failed to read ID ' +
              String(McpDeviceService.ID_DOOR_LEARN_STATE) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async readEndPositionType(
    maxTryCount: number = McpDeviceService.DEFAULT_READ_MAX_TRY_COUNT
  ): Promise<EndPositionTypeEnum> {
    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        const values = await this.comm.readId(
          McpDeviceService.ID_END_POSITION_TYPE
        );
        const endPositionType = values[0] as EndPositionTypeEnum;
        console.log(
          '>>> readEndPositionType endPositionType',
          EndPositionTypeEnum[endPositionType]
        );
        return endPositionType;
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '>>> readEndPositionType failed to read ID ' +
              String(McpDeviceService.ID_END_POSITION_TYPE) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async readRotatingField(
    maxTryCount: number = McpDeviceService.DEFAULT_READ_MAX_TRY_COUNT
  ): Promise<RotatingFieldEnum> {
    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        const values = await this.comm.readId(
          McpDeviceService.ID_ROTATING_FIELD
        );
        const rotatingField = values[0] as RotatingFieldEnum;
        console.log(
          '>>> readRotatingField rotatingField',
          RotatingFieldEnum[rotatingField]
        );
        return rotatingField;
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '>>> readRotatingField failed to read ID ' +
              String(McpDeviceService.ID_ROTATING_FIELD) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async readIntermediatePositions(
    maxTryCount: number = McpDeviceService.DEFAULT_READ_MAX_TRY_COUNT
  ): Promise<number[]> {
    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        const values = await this.comm.readId(
          McpDeviceService.ID_INTERMEDIATE_POSITIONS
        );
        console.log(
          '>>> readIntermediatePositions intermediatePositions',
          values
        );
        return values;
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '>>> readIntermediatePositions failed to read ID ' +
              String(McpDeviceService.ID_INTERMEDIATE_POSITIONS) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async readDiagnosisEndPositionFineSettings(
    maxTryCount: number = McpDeviceService.DEFAULT_READ_MAX_TRY_COUNT
  ): Promise<number[]> {
    const offset =
      (McpDeviceService.END_POSITION_FINE_SETTINGS_MAX -
        McpDeviceService.END_POSITION_FINE_SETTINGS_MIN) /
      2;

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        const values = (
          await this.comm.readId(
            McpDeviceService.ID_DIAGNOSIS_END_POSITION_FINE_SETTINGS
          )
        ).map((x: number) => x - offset); // 0..2000 => -1000..1000
        console.log(
          '>>> readDiagnosisEndPositionFineSettings endPositionFineSettings',
          values
        );
        return values;
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '>>> readDiagnosisEndPositionFineSettings failed to read ID ' +
              String(McpDeviceService.ID_DIAGNOSIS_END_POSITION_FINE_SETTINGS) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async readCurrentDoorPosition(
    maxTryCount: number = McpDeviceService.DEFAULT_READ_MAX_TRY_COUNT
  ): Promise<CurrentDoorPositionEnum> {
    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        const values = await this.comm.readId(
          McpDeviceService.ID_CURRENT_DOOR_POSITION
        );
        const currentDoorPosition = values[0] as CurrentDoorPositionEnum;
        console.log(
          '>>> readCurrentDoorPosition readCurrentDoorPosition',
          CurrentDoorPositionEnum[currentDoorPosition]
        );
        return currentDoorPosition;
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            '>>> readCurrentDoorPosition failed to read ID ' +
              String(McpDeviceService.ID_CURRENT_DOOR_POSITION) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeLearningCommand(
    learningCommand: LearningCommandEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '>>> writeLearningCommand learningCommand',
      LearningCommandEnum[learningCommand]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          McpDeviceService.ID_LEARNING_COMMAND,
          learningCommand
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            'Failed to write ' +
              String(learningCommand) +
              ' to ID ' +
              String(McpDeviceService.ID_LEARNING_COMMAND) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeLearningImePosition(
    learningImePosition: LearningImePositionEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '>>> writeLearningImePosition learningImePosition',
      LearningImePositionEnum[learningImePosition]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          McpDeviceService.ID_LEARNING_IME_POSITION,
          learningImePosition
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            'Failed to write ' +
              String(learningImePosition) +
              ' to ID ' +
              String(McpDeviceService.ID_LEARNING_IME_POSITION) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeEndPositionType(
    endPositionType: EndPositionTypeEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '>>> writeEndPositionType endPositionType',
      EndPositionTypeEnum[endPositionType]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          McpDeviceService.ID_END_POSITION_TYPE,
          endPositionType
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            'Failed to write ' +
              String(endPositionType) +
              ' to ID ' +
              String(McpDeviceService.ID_END_POSITION_TYPE) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeRotatingField(
    rotatingField: RotatingFieldEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '>>> writeRotatingField rotatingField',
      RotatingFieldEnum[rotatingField]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          McpDeviceService.ID_ROTATING_FIELD,
          rotatingField
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            'Failed to write ' +
              String(rotatingField) +
              ' to ID ' +
              String(McpDeviceService.ID_ROTATING_FIELD) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeDeleteImePosition(
    deleteImePosition: DeleteImePositionEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '>>> writeDeleteImePosition deleteImePosition',
      DeleteImePositionEnum[deleteImePosition]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          McpDeviceService.ID_DELETE_IME_POSITION,
          deleteImePosition
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            'Failed to write ' +
              String(deleteImePosition) +
              ' to ID ' +
              String(McpDeviceService.ID_DELETE_IME_POSITION) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeImpulsTarget(
    impulsTarget: ImpulsTargetEnum,
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '>>> writeImpulsTarget impulsTarget',
      ImpulsTargetEnum[impulsTarget]
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          McpDeviceService.ID_IMPULS_TARGET,
          impulsTarget
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            'Failed to write ' +
              String(impulsTarget) +
              ' to ID ' +
              String(McpDeviceService.ID_IMPULS_TARGET) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }

  private async writeDiagnosisEndPositionFineSettings(
    endPositionFineSettings: number[],
    maxTryCount: number = McpDeviceService.DEFAULT_WRITE_MAX_TRY_COUNT
  ): Promise<void> {
    console.log(
      '>>> writeDiagnosisEndPositionFineSettings endPositionFineSettings',
      endPositionFineSettings
    );

    // convert (and boundary-correct) -200..200 => 0..400
    const offset =
      (McpDeviceService.END_POSITION_FINE_SETTINGS_MAX -
        McpDeviceService.END_POSITION_FINE_SETTINGS_MIN) /
      2;
    endPositionFineSettings = endPositionFineSettings.map((x: number) =>
      Math.min(
        McpDeviceService.END_POSITION_FINE_SETTINGS_MAX,
        Math.max(x + offset, McpDeviceService.END_POSITION_FINE_SETTINGS_MIN)
      )
    );

    let tryCount = 0;
    while (true) {
      tryCount++;
      try {
        return await this.comm.writeId(
          McpDeviceService.ID_DIAGNOSIS_END_POSITION_FINE_SETTINGS,
          endPositionFineSettings
        );
      } catch (error: any) {
        if (tryCount >= maxTryCount) {
          console.error(
            'Failed to write ' +
              String(endPositionFineSettings) +
              ' to ID ' +
              String(McpDeviceService.ID_DIAGNOSIS_END_POSITION_FINE_SETTINGS) +
              ': ' +
              String(error)
          );
          throw error;
        }
      }
    }
  }
}
