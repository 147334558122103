import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'mcp-press-release-button',
  templateUrl: 'press-release-button.component.html',
  styleUrls: ['press-release-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PressReleaseButtonComponent {
  @Input() disabled: boolean = false;
  @Output() buttonPressed: EventEmitter<void> = new EventEmitter<void>();
  @Output() buttonReleased: EventEmitter<void> = new EventEmitter<void>();

  private _isButtonCurrentlyPressed: boolean = false;

  buttonPressedHandler(event: Event): void {
    event.preventDefault();
    this._isButtonCurrentlyPressed = true;
    this.buttonPressed.emit();
  }

  contextMenuHandler(event: Event): void {
    event.preventDefault();
  }

  buttonReleasedHandler(event: Event): void {
    event.preventDefault();
    this._isButtonCurrentlyPressed = false;
    this.buttonReleased.emit();
  }

  buttonLeftHandler(event: Event): void {
    if (!this._isButtonCurrentlyPressed) {
      return;
    }

    this.buttonReleasedHandler(event);
  }
}
