import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';

import { ActiveProcessEnum } from '../enums';
import { Injectable } from '@angular/core';

@Injectable()
export class McpActiveProcessService {
  private readonly _defaultValueOfActiveProcess: ActiveProcessEnum =
    ActiveProcessEnum.proc_idle;

  private readonly _currentlyActiveProcess$: BehaviorSubject<ActiveProcessEnum>;
  private _activeProcessSwitchingDisabled = false;

  constructor() {
    this._currentlyActiveProcess$ = new BehaviorSubject<ActiveProcessEnum>(
      this._defaultValueOfActiveProcess
    );
  }

  getCurrentlyActiveProcess$(): Observable<ActiveProcessEnum> {
    return this._currentlyActiveProcess$.pipe(distinctUntilChanged());
  }

  setCurrentlyActiveProcess(process: ActiveProcessEnum): void {
    if (this._activeProcessSwitchingDisabled) {
      return;
    }

    this._currentlyActiveProcess$.next(process);
  }

  toggleActiveProcessSwitching(value: boolean): void {
    this._activeProcessSwitchingDisabled = value;
  }
}
