import { Router, UrlTree } from '@angular/router';

import { Injectable } from '@angular/core';
import { NavigationParameters } from './navigation-parameters.type';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  forwardScreen: string[];
  returnScreen: string[];

  private _returnScreenFromErrorHandlingOverlay: string[] | null;

  constructor(private readonly _router: Router) {}

  setupParameters(parameters: NavigationParameters): void {
    this.returnScreen = parameters['returnScreen'] ?? [];
    this.forwardScreen = parameters['forwardScreen'] ?? [];
  }

  setReturnScreenFromErrorHandlingOverlay(screenPath: string[] | null): void {
    this._returnScreenFromErrorHandlingOverlay = screenPath;
  }

  navigate(path: any[], state?: NavigationParameters): void {
    if (state?.['returnScreen']) {
      this.returnScreen = state['returnScreen'];
    }
    if (state?.['forwardScreen']) {
      this.forwardScreen = state['forwardScreen'];
    }
    this._router.navigate(path);
  }

  navigateByUrl(path: string | UrlTree, state: NavigationParameters): void {
    if (state['returnScreen']) {
      this.returnScreen = state['returnScreen'];
    }
    if (state['forwardScreen']) {
      this.forwardScreen = state['forwardScreen'];
    }
    this._router.navigateByUrl(path, { state });
  }

  getNavigationParameters(): NavigationParameters {
    return (this._router.getCurrentNavigation()?.extras.state ?? {
      forwardScreen: this.forwardScreen,
      returnScreen: this.returnScreen
    }) as NavigationParameters;
  }

  getReturnScreenFromErrorHandlingOverlay(): string[] | null {
    return this._returnScreenFromErrorHandlingOverlay;
  }
}
