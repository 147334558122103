import { MAIN_ROUTES, ROUTES } from "@app/app-routing.config";

export const GS_ROUTES = {
  FACTORY_RESET: 'factory-reset',
  OVERVIEW: 'gs-overview'
}

export const crumbGeneralSettings = {
  label: 'main.list.generalSettings.title',
  link: [
    ROUTES.MAIN,
    MAIN_ROUTES.GENERAL_SETTINGS,
    GS_ROUTES.OVERVIEW
  ]
};

export const crumbGeneralSettingsOverview = {
  label: 'main.generalSettings.overview.title',
  link: [
    ROUTES.MAIN,
    MAIN_ROUTES.GENERAL_SETTINGS,
    GS_ROUTES.OVERVIEW
  ]
};

export const crumbFactoryReset = {
  label: 'main.generalSettings.factoryReset.title',
  link: [
    ROUTES.MAIN,
    MAIN_ROUTES.GENERAL_SETTINGS,
    GS_ROUTES.FACTORY_RESET
  ]
};