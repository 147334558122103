/* eslint-disable @typescript-eslint/naming-convention */

export enum McpDeviceCommStateEnum {
  STATE_DISCONNECTED,
  STATE_CONNECTING,
  STATE_CONNECTED_IDLE,
  STATE_CONNECTED_BUSY
}

export abstract class AbstractMcpDeviceCommService {
  // check if browser is compatible with WebBT (depending on the bwrowser WebBT might need to be enabled first, and page must be served by a secure context)
  // returns:
  //   compatibility (true:compatible, false: incompatible)
  abstract isBrowserCompatible(): boolean;

  // connect to device and register callbacks
  // arguments:
  //   onReceiveUpdate(json): optional callback to call when a previously registered ID receives an update (json: received json object from device)
  //   onConnectionLost(): optional callback to call upon unexpected connection loss (will only be called when we were actually connected in the first place)
  // returns:
  //   connect promise with empty result
  abstract connect(onReceiveUpdate?: any, onConnectionLost?: any): Promise<any>;

  // disconnect from device and unregister callbacks
  // returns:
  //   disconnect promise with empty result
  abstract disconnect(): Promise<any>;

  // obtains the current state of the stack
  // returns:
  //   state (STATE_DISCONNECTED, STATE_CONNECTING, STATE_CONNECTED_IDLE or STATE_CONNECTED_BUSY)
  abstract getState(): McpDeviceCommStateEnum;

  // check if connection to device is established
  // returns:
  //   connection state (true:connected, false: disconnected)
  abstract isConnected(): boolean;

  // check if stack is idle or currently processing a request (if the connection is not established, it will return false as well)
  // returns:
  //   idle state (true:idle, false: processing/not connected)
  abstract isIdle(): boolean;

  // obtains the connected device id
  // returns:
  //   id if connected (else empty string)
  abstract getDeviceId(): string;

  // obtains the connected device name
  // returns:
  //   name if connected (else empty string)
  abstract getDeviceName(): string;

  // set/clear debug log callback
  // arguments:
  //   onDebugLog(message): optional callback to call upon any debug output
  abstract setDebugLogCallback(onDebugLog?: any): void;

  // set/clear received response callback
  // arguments:
  //   onReceiveResponse(json): optional callback to call upon receiving any json object from device
  abstract setReceiveResponseCallback(onReceiveResponse?: any): void;

  // set/reset default response timeout
  // arguments:
  //   defaultTimeoutMs: optional timeout in ms to wait for a response after sending a request (else request will fail with a timeout error)
  abstract setDefaultResponseTimeout(defaultTimeoutMs?: number): void;

  // Read id from device
  // arguments:
  //   id: numerical ID to read
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   read id promise with array of the received values as result
  abstract readId(id: number, responseTimeoutMs?: number): Promise<any[]>;

  // Write id to device
  // arguments:
  //   id: numerical ID to write
  //   values: array of values to write
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   write id promise with empty result
  abstract writeId(
    id: any,
    values: number | number[],
    responseTimeoutMs?: number
  ): Promise<any>;

  // Register id(s) to get automatic updates for from device
  // arguments:
  //   ids: array of numerical IDs to register
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   register id promise with empty result
  abstract registerId(
    ids: number | number[],
    responseTimeoutMs?: number
  ): Promise<any>;

  // Unregister id(s) to get automatic updates for from device
  // arguments:
  //   ids: array of numerical IDs to unregister
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   unregister id promise with empty result
  abstract unregisterId(
    ids: number | number[],
    responseTimeoutMs?: number
  ): Promise<any>;

  abstract sendRawData(data: Uint8Array): Promise<void>;

  // Get all available ids from device
  // arguments:
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   get all ids promise with array of the received IDs as result
  abstract getAllIds(responseTimeoutMs?: number): Promise<any[]>;

  // Get id structure from device
  // arguments:
  //   id: numerical ID to get structure of
  //   responseTimeoutMs: optional response timeout
  // returns:
  //   get id structure promise with structure json object as result
  abstract getIdStructure(id: number, responseTimeoutMs?: number): Promise<any>;
}
