import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotificationComponent } from './notification.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  declarations: [NotificationComponent],
  exports: [NotificationComponent, SharedModule]
})
export class NotificationModule {}
