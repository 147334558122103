import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { Checkbox } from './checkbox.interface';

@Component({
  selector: 'mcp-checkbox-list',
  templateUrl: 'checkbox-list.component.html',
  styleUrls: ['checkbox-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CheckboxListComponent {
  @Input() items: Checkbox[] = [];
  @Output() changeEvent: EventEmitter<Checkbox> = new EventEmitter<Checkbox>();

  isDisabled = false;

  onClick(item: Checkbox) {
    item.checked = !item.checked;
    this.changeEvent.emit(item);
  }
}
