<div
  class="remote-control d-flex flex-column justify-content-between"
>
  <p *ngIf="description" class="remote-control__description">
    {{ description | transloco }}
  </p>

  <div class="remote-control__door-image {{doorState}}"></div>
  <div class="remote-control__gate-action-buttons">
    <mcp-press-release-button *ngIf="hasOpenButton"
      (buttonPressed)="moveDoorClickHandler(positionType.epo)"
      [disabled]="false"
    >
      <img
        src="/assets/icons/test-end-position-opened.svg"
        alt="Button End Position Up"
      />
    </mcp-press-release-button>
    <mcp-press-release-button *ngIf="hasIntermediateOpenButton"
      (buttonPressed)="moveDoorClickHandler(positionType.ipo)"
      [disabled]="intermediateOpenedDisabled"
    >
      <img
        src="/assets/icons/test-intermediate-position-opened.svg"
        alt="Button Intermediate Position Up"
      />
    </mcp-press-release-button>
    <mcp-press-release-button *ngIf="hasStopButton"
      (buttonPressed)="stopClickHandler()"
    >
      <img
        src="/assets/icons/stop.svg"
        alt="Button Stop"
      />
    </mcp-press-release-button>
    <mcp-press-release-button *ngIf="hasIntermediateCloseButton"
      (buttonPressed)="moveDoorClickHandler(positionType.ipc)"
      [disabled]="intermediateClosedDisabled"
    >
      <img
        src="/assets/icons/test-intermediate-position-closed.svg"
        alt="Button Intermediate Position Down"
      />
    </mcp-press-release-button>
    <mcp-press-release-button *ngIf="hasCloseButton"
      (buttonPressed)="moveDoorClickHandler(positionType.epc)"
      [disabled]="false"
    >
      <img
        src="/assets/icons/test-end-position-closed.svg"
        alt="Button End Position Down"
      />
    </mcp-press-release-button>
  </div>
  <div class="remote-control__gate-action-buttons secondary">
    <mcp-press-release-button *ngIf="hasDeactivationPointOpenButton"
      (buttonPressed)="moveDoorClickHandler(positionType.deactivationPointOpen)"
      [disabled]="false"
    >
    Deactivation Open
      <!-- <img
        src="/assets/icons/test-end-position-opened.svg"
        alt="Button Deactivation Point Open"
      /> -->
    </mcp-press-release-button>
    <mcp-press-release-button *ngIf="hasDeactivationPointCloseButton"
      (buttonPressed)="moveDoorClickHandler(positionType.deactivationPointClose)"
      [disabled]="false"
    >
    Deactivation Closed
      <!-- <img
        src="/assets/icons/test-intermediate-position-opened.svg"
        alt="Button Deactivation Point Closed"
      /> -->
    </mcp-press-release-button>
    <mcp-press-release-button *ngIf="hasLSPointButton"
      (buttonPressed)="moveDoorClickHandler(positionType.lsPoint)"
      [disabled]="false"
    >
    LS Point
      <!-- <img
        src="/assets/icons/stop.svg"
        alt="Button LS Point"
      /> -->
    </mcp-press-release-button>
    <mcp-press-release-button *ngIf="hasSafetyEdgeTestPointButton"
      (buttonPressed)="moveDoorClickHandler(positionType.safetyEdgeTestPoint)"
      [disabled]="false"
    >
    Safety Edge Test Point
      <!-- <img
        src="/assets/icons/test-intermediate-position-closed.svg"
        alt="Button Safety Edge Test Point"
      /> -->
    </mcp-press-release-button>
  </div>
</div>