import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { CardButton } from "@app/shared/interfaces/interfaces";

@Component({
  selector: 'mcp-card-button-bar',
  templateUrl: 'card-button-bar.component.html',
  styleUrls: ['card-button-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardButtonBarComponent {
  @Input() buttons: CardButton[] = [];
  @Output() buttonClicked: EventEmitter<CardButton> = new EventEmitter<CardButton>();

  onClick(button: CardButton) {
    this.buttonClicked.emit(button);
  }
}
