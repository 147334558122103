<div class="p-1">
  <div class="form-group">
    <ng-container *ngFor="let item of items; let index">
      <div class="form-check">
        <input class="form-check-input" type="radio" name="{{groupId}}" value="{{item.id}}" [(ngModel)]="selectedId" (ngModelChange)="onChange(item)" [disabled]="isDisabled">
        <label class="form-check-label" for="{{groupId}}-{{index}}">
          {{ item.title }}
        </label>
      </div>
    </ng-container>
  </div>
</div>